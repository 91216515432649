









































































































































import { Component } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { Getter } from 'vuex-class';

import SingleColumnLayout from '@/shared/components/common/SingleColumnLayout.vue';
import * as visaSingle from '@/assets/common/cc_img/single/visa.svg';
import * as mcSingle from '@/assets/common/cc_img/single/mastercard.svg';
import * as amexSingle from '@/assets/common/cc_img/single/amex.svg';
import * as discoverSingle from '@/assets/common/cc_img/single/discover.svg';
import * as genericMono from '@/assets/common/cc_img/mono/default.svg';
import { optionForDuration, adNetworksCopy } from '@/shared/lib/budgetOptions';
import { Campaign } from '@/shared/store/campaign';
import VerifyEmailNotice from '@/shared/components/common/VerifyEmailNotice.vue';
import AuthRequired from '@/shared/components/AuthRequired';

@Component({
  mixins: [validationMixin],
  components: {
    VerifyEmailNotice,
    SingleColumnLayout,
  },
})
export default class Receipt extends AuthRequired {
  $refs!: any;

  loading: boolean = false;

  @Getter('profile/requiresEmailVerification') requiresEmailVerification: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('campaign/campaignDetails') campaignDetails: Campaign;

  mounted() {
    const promiseList = [];
    if (this.campaignDetails.id !== this.$route.params.id) {
      promiseList.push(this.$store.dispatch('campaign/load', this.$route.params.id));
    }
    if (!this.receipt) {
      promiseList.push(this.$store.dispatch('payment/loadReceiptForCampaign', this.$route.params.id));
    }

    Promise.all(promiseList).then(() => {
      const numberUserImagesApplied = this.$store.getters['onboarding/getNumDraftImages'];
      const numberStockImagesApplied = this.$store.getters['onboarding/getNumDraftMemberImages'];
      const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.releaseType,
          releaseGenre: this.releaseGenre,
          domainUrl: this.$store.getters['campaign/getLandingPageDomains'],
          // onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
          numberUserImagesApplied,
          numberStockImagesApplied,
          numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
          numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
          numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
          numberIndividuallyEditedAds: creativeControlMixpanelData.numberAdsEdited + creativeControlMixpanelData.numberUserDisabledAds,
          numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
          numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
          numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
          numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
          numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
          numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
          creativeControlEditsMade: creativeControlMixpanelData.creativeControlEditsMade,
          hasAudioApplied: this.$store.getters['campaign/hasAudio'],
          appliedBudget: this.campaignDetails.budget!.amount / 100,
          appliedDurationDays: this.campaignDetails.durationDays,
          verifyEmailWallShown: this.requiresEmailVerification,
          referringDiscountCode: this.receipt.couponCode,
          amountPaid: this.receipt.amount.amount / 100,
          discountAmount: (this.campaignDetails.budget!.amount - this.receipt.amount.amount) / 100,
          appliedAdNetworks: this.campaignDetails.adNetworks,
        },
        action: 'Zire.ReceiptPageViewed',
      });
      this.$store.dispatch('mixpanel/resetTrackOnceEventList');
    });
  }

  get receipt() {
    return this.$store.getters['payment/receiptForCampaign'](this.$route.params.id);
  }

  get campaignDisplayDurationSingular() {
    const duration = this.campaignDetails.durationDays;
    if (!duration) {
      return null;
    }
    const option = optionForDuration(duration);
    if (option) {
      return option.durationSingular.toLowerCase();
    }
    return null;
  }

  get cardIconSrc() {
    switch ((this.receipt.cardType || '').toLowerCase()) {
      case 'visa':
        return visaSingle;
      case 'mastercard':
        return mcSingle;
      case 'amex':
      case 'american express':
        return amexSingle;
      case 'discover':
        return discoverSingle;
      default:
        return genericMono;
    }
  }

  get adNetworksCopy() {
    return adNetworksCopy(this.campaignDetails.adNetworks);
  }

  getUGC(Key: string): string {
    if (!this.campaignDetails || !this.campaignDetails.ugc) {
      return '';
    }
    if (this.campaignDetails.ugc!.fields[Key]) {
      return this.campaignDetails.ugc!.fields[Key];
    }
    return '';
  }

  get artistName() {
    return this.getUGC('artistName');
  }

  get releaseName() {
    return this.getUGC('releaseName');
  }

  get releaseType() {
    return this.getUGC('releaseType');
  }

  get releaseGenre() {
    return this.getUGC('releaseGenre');
  }

  get getDate() {
    const d = new Date(this.receipt.created);
    return `${(`0${(d.getMonth() + 1).toString()}`).slice(-2)}/${(`0${d.getDate().toString()}`).slice(-2)}/${d.getFullYear()}`;
  }

  goToListings() {
    this.loading = true;
    this.$nextTick(() => {
      this.$router.push({ name: 'campaigns' });
      this.loading = false;
    });
  }
}
