

























import { Component, Vue } from 'vue-property-decorator';
import StandardLayout from '@/shared/components/common/StandardLayout.vue';
import LogoLink from '@/shared/components/site/LogoLink.vue';

@Component({
  components: {
    LogoLink,
    StandardLayout,
  },
})
export default class SingleColumnLayout extends Vue {
}
